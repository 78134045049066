import React from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button, Row, Col, Card } from "antd";
import {
  SearchOutlined,
  ClockCircleOutlined,
  SmileOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const cities = useSelector((state: RootState) => state.cities.cities);

  const Search = (value: string) => {
    navigate(`/листинг/${value}`);
  };

  return (
    <div className="main-page">
      <h1 className="title">Агрегатор сиделок города Самара</h1>
      <div className="section main_first_block">
        <img src="/images/Kx1smYbRFg9wwEXjWud_M.png" alt="Сиделка" loading="lazy" className="main_image"/>
        <div className="main_block_content">
          <p className="main_first_block_text">
            Найдите надёжную и профессиональную сиделку для ухода за близкими. 
            Мы предоставляем квалифицированных специалистов, которые помогут создать 
            комфорт и обеспечить заботу вашим родным. Будьте уверены, что рядом с ними 
            будет человек, который поддержит и поможет в нужный момент.
          </p>
          <Select
            showSearch
            placeholder="Выберите для кого"
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? "")
                .toLowerCase()
                .localeCompare((optionB?.name ?? "").toLowerCase())
            }
            onChange={Search}
            className="search text"
          >
            {cities.map((city) => (
              <Select.Option key={city.id} value={city.name}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="section text">
        <h2 className="subtitle">Наши услуги</h2>
        <div className="card-container">
          <div className="card">
            <img
              src="/images/Vo7_-we6LVbYNjS3SZltY.png"
              alt="Сиделка на дом"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Уход на дому</h3>
            <p>
              Профессиональный уход за близкими в привычных домашних условиях, 
              чтобы они чувствовали себя комфортно и безопасно.
            </p>
            <Button
              color="primary"
              variant="solid"
              onClick={() => navigate("/листинг/на дом")}
            >
              Выбрать
            </Button>
          </div>
          <div className="card">
            <img
              src="/images/65ZHgbON2cjLKKpordzQr.png"
              alt="Сиделка в больницу"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Уход в больнице</h3>
            <p>
              Помощь вашим родным во время пребывания в больнице, где они получат 
              внимание и заботу от профессионала.
            </p>
            <Button
              color="primary"
              variant="solid"
              onClick={() => navigate("/листинг/в больницу")}
            >
              Выбрать
            </Button>
          </div>
          <div className="card">
            <img
              src="/images/dLJeUghvNRovQuD4YCQdX.png"
              alt="Сиделка на мероприятие"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Сопровождение на мероприятие</h3>
            <p>
              Поддержка и уход на мероприятиях, чтобы ваши близкие не чувствовали себя одиноко и беспомощно.
            </p>
            <Button
              color="primary"
              variant="solid"
              onClick={() => navigate("/листинг/на мероприятие")}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </div>

      <div className="section advantages">
        <h2 className="subtitle">Почему выбирают нас</h2>
        <Row gutter={[16, 16]} className="advantages-list">
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <SearchOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Быстрый поиск сиделок</h3>
              <p className="text">
                Найдите подходящую сиделку для ваших близких за считанные минуты.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <ClockCircleOutlined
                style={{ fontSize: "36px", color: "#1890ff" }}
              />
              <h3 className="h3">Удобное бронирование</h3>
              <p className="text">
                Забронируйте сиделку быстро и без сложностей в использовании.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <SmileOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Надежные и проверенные</h3>
              <p className="text">
                Мы тщательно проверяем каждого специалиста, чтобы гарантировать качество услуг.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <ClockCircleOutlined
                style={{ fontSize: "36px", color: "#1890ff" }}
              />
              <h3 className="h3">Гибкий график</h3>
              <p className="text">
                Удобное расписание, которое подстраивается под ваши нужды.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <SmileOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Прозрачные условия</h3>
              <p className="text">
                Оплата напрямую с сиделкой, без скрытых комиссий.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <CustomerServiceOutlined
                style={{ fontSize: "36px", color: "#1890ff" }}
              />
              <h3 className="h3">Круглосуточная поддержка</h3>
              <p className="text">
                Мы всегда готовы помочь с любыми вопросами и предоставить информацию.
              </p>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="section seo-text block">
        <h2 className="subtitle">Найдите сиделку для ухода за вашими близкими</h2>
        <p className="text">
          Наш сервис поможет вам быстро и легко найти профессиональную сиделку в вашем городе.
          Независимо от места проживания ваших близких, будь то Самара, Москва или другой город, 
          мы предоставим проверенных специалистов, которые обеспечат надёжный уход и поддержку.
        </p>
      </div>
    </div>
  );
};

export default MainPage;

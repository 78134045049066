import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Filter from "./Filter.tsx";
import FAQ from "./FAQ.tsx";
import SeoArticle from "./SeoArticle.tsx";
import { fetchObjects } from "../utils/api.ts";
import { useAppDispatch } from "../hooks/useAppDispatch.ts";
import { useSelector } from "react-redux";
import { RootState } from "../store/store.ts";
import { setCity, setServiceType } from "../store/slices/filterSlice.ts";
import { List, Button, Rate } from "antd";
import Loading from "./Loading.tsx";
import { Santa } from "../types.ts";
import { serviceTypes } from "./constants.ts";

(window as any).isTitleReady = false;

const ObjectList: React.FC = () => {
  const dispatch = useAppDispatch();
  const cities = useSelector((state: RootState) => state.cities.cities);
  const loading = useSelector((state: RootState) => state.cities.loading);
  const { city: cityParam, serviceType: serviceTypeParam } = useParams<{
    city: string;
    serviceType: string;
  }>();

  const city = useSelector((state: RootState) => state.filters.city);
  const serviceType = useSelector(
    (state: RootState) => state.filters.serviceType
  );

  const [objects, setObjects] = useState<Santa[]>([]);
  const [selectedCityDeclension, setSelectedCityDeclension] = useState<
    string | null
  >(null);
  const [minPrice, setMinPrice] = useState<number>(0);

  useEffect(() => {
    const getObjects = async () => {
      try {
        const data = await fetchObjects();

        const filteredByCity = city
          ? data.filter((object) =>
              object.cities.some((objectCity: string) =>
                objectCity.includes(city)
              )
            )
          : data;

        const filteredByDop = serviceType
          ? filteredByCity.filter((object) =>
              object.dop.some((dopItem: string) =>
                dopItem.includes(serviceType)
              )
            )
          : filteredByCity;

        const shuffledObjects = filteredByDop.sort(() => Math.random() - 0.5);

        setObjects(shuffledObjects);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };


    const getCities = async () => {
      try {
        const selectedCity = cities.find((c) => c.name === city);
        if (selectedCity) {
          setSelectedCityDeclension(selectedCity.declension);
        }
      } catch (error) {
        console.error("Ошибка при получении городов:", error);
      }
    };

    if (cityParam) {
      const isCity = cities.some((city) => city.name === cityParam);
      const isServiceType = serviceTypes.includes(cityParam);

      if (isCity) {
        dispatch(setCity(cityParam));
      } else if (isServiceType) {
        dispatch(setServiceType(cityParam));
      }
    }

    if (serviceTypeParam) {
      dispatch(setServiceType(serviceTypeParam));
    }

    getObjects();
    getCities();
  }, [cities, city, serviceType, cityParam, serviceTypeParam, dispatch]);

  useEffect(() => {
    if (objects.length > 0) {
      const minPriceValue = Math.min(...objects.map((item) => item.price));
      setMinPrice(minPriceValue);
    } else {
      setMinPrice(5000);
    }
  }, [objects]);

  const titleSuffix = selectedCityDeclension
    ? `${selectedCityDeclension} ${serviceType ? serviceType : ""}`.trim()
    : serviceType || "";

    useEffect(() => {
      if (!loading) {
        if (city && serviceType) {
          if (selectedCityDeclension) {
            (window as any).isTitleReady = true;
          } else {
            (window as any).isTitleReady = false;
          }
        }
        else if (city) {
          if (selectedCityDeclension) {
            (window as any).isTitleReady = true;
          } else {
            (window as any).isTitleReady = false;
          }
        }
        else if (serviceType) {
          (window as any).isTitleReady = true;
        }
        else {
          (window as any).isTitleReady = false;
        }
      }
    }, [city, selectedCityDeclension, serviceType, loading]);

  return (
    <div className="listing">
      <div className="listing_top">
        <div className="block filters">
          <Filter />
        </div>
        <div className="block">
          <Helmet>
            <title>
              {city
                ? `Сиделки ${titleSuffix}`
                : `Сиделки ${serviceType ? ` ${serviceType}` : ""}`}
            </title>
            <meta
              name="description"
              content={
                city
                  ? `Найдено ${objects.length} сиделок ${titleSuffix} по цене от ${minPrice} рублей`
                  : `Список всех сиделок ${
                      serviceType ? ` ${serviceType}` : ""
                    }`
              }
            />
          </Helmet>
          <h1 className="title">
            {city
              ? `Сиделки ${titleSuffix}`
              : `Сиделки ${serviceType ? ` ${serviceType}` : ""}`}
          </h1>
          {loading ? (
            <Loading />
          ) : (
            <List
              header={<h2 className="h3">Выберите сиделку</h2>}
              bordered
              dataSource={objects}
              renderItem={(object) => (
                <div className="listing_item" key={object._id}>
                  <img
                    src={object.image}
                    alt={object.name}
                    className="listing_item_img"
                    loading="lazy"
                  />
                  <div className="listing_item_content text">
                    <span className="listing_item_title">{object.name}</span>
                    <span>Стоимость: от {object.price} руб.</span>
                    <div>
                      Рейтинг:
                      <Rate
                        disabled
                        defaultValue={object.rating}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </div>
                  <Button
                    color="primary"
                    variant="solid"
                    className="listing_item_button"
                    onClick={() => {
                      window.open(`tel:${object.telephone}`, "_blank");
                    }}
                  >
                    Позвонить
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      </div>
      <SeoArticle
        location={selectedCityDeclension}
        serviceType={serviceType}
        priceFrom={minPrice}
      />

      <FAQ />
    </div>
  );
};

export default ObjectList;

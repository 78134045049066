import React from "react";
import { Collapse } from "antd";

const faqData = [
    {
        key: "1",
        label: "Кто такая сиделка?",
        children:
            "Сиделка — это квалифицированный специалист по уходу за пожилыми или лежачими людьми, который помогает в повседневных делах и оказывает поддержку в медицинских и социальных вопросах.",
    },
    {
        key: "2",
        label: "Как заказать услуги сиделки?",
        children:
            "Вы можете заказать сиделку через наш сайт, выбрав подходящие дату и время, а также нужный вид ухода, после чего мы свяжем вас с исполнителем.",
    },
    {
        key: "3",
        label: "Какие услуги предоставляет сиделка?",
        children:
            "Сиделка может помогать с личной гигиеной, приготовлением пищи, принятием лекарств, прогулками и медицинскими процедурами. Подробный список услуг можно обсудить с исполнителем.",
    },
    {
        key: "4",
        label: "Какова стоимость услуг сиделки?",
        children:
            "Стоимость услуг зависит от уровня квалификации сиделки и объема работы. Рекомендуем обсудить стоимость и условия с выбранным специалистом.",
    },
    {
        key: "5",
        label: "Как выбрать подходящую сиделку?",
        children:
            "На нашем сайте вы можете просмотреть профили сиделок, ознакомиться с их опытом, отзывами клиентов и рейтингами, чтобы выбрать подходящего специалиста.",
    },
    {
        key: "6",
        label: "Есть ли у вас сиделки в моем городе?",
        children:
            "Да, у нас есть специалисты в разных городах. Вы можете выбрать нужный город при поиске на нашем сайте.",
    },
    {
        key: "7",
        label: "Как организуется работа с сиделкой с проживанием?",
        children:
            "Сиделки с проживанием обеспечивают круглосуточный уход и помощь. Условия проживания и работы оговариваются перед началом сотрудничества.",
    },
    {
        key: "8",
        label: "Можно ли заказать услуги сиделки на короткий срок?",
        children:
            "Да, вы можете заказать услуги сиделки на несколько часов, дней или недель, в зависимости от ваших потребностей.",
    },
    {
        key: "9",
        label: "Как отменить заказ?",
        children:
            "Если вам нужно отменить заказ, свяжитесь с выбранным специалистом через нашу платформу для согласования отмены.",
    },
    {
        key: "10",
        label: "Что делать, если сиделка опоздала?",
        children:
            "В случае опоздания сиделки, пожалуйста, свяжитесь с ней напрямую через нашу платформу для уточнения ситуации.",
    },
    {
        key: "11",
        label: "Можно ли указать особые пожелания для ухода?",
        children:
            "Да, вы можете указать любые особые требования при заказе, и мы передадим их исполнителю.",
    },
    {
        key: "12",
        label: "Как оплатить услуги сиделки?",
        children:
            "Оплата производится напрямую с выбранной сиделкой. Обсудите удобные для вас способы оплаты перед началом работы.",
    },
];

const FAQ: React.FC = () => {
    return (
        <div className="block">
            <h2 className="subtitle">Часто задаваемые вопросы</h2>
            <Collapse accordion items={faqData} className="text" />
        </div>
    );
};

export default FAQ;

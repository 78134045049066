import React from "react";
import { SeoArticleProps } from "../types";

const SeoArticle: React.FC<SeoArticleProps> = ({
  location = "в вашем городе",
  serviceType = "на дом",
  priceFrom = 1500,
}) => {
  return (
    <section className="block">
      <h2 className="subtitle">
        Заказать сиделку {location} {serviceType}
      </h2>
      <article>
        <p className="article_text">
          Обеспечьте своим близким заботу и комфорт — закажите услуги сиделки
          {location}. Наши квалифицированные специалисты приедут {serviceType},
          чтобы оказать необходимую поддержку и заботу. Профессиональные сиделки
          обеспечат полный уход за вашими близкими, помогут в повседневных
          делах и предоставят эмоциональную поддержку.
        </p>
        <p className="article_text">
          Сиделка — это не просто помощник, но и друг, который поддержит в
          сложные моменты. Каждый наш специалист имеет опыт и внимательный
          подход, чтобы заботиться о людях, которые нуждаются в помощи. Закажите
          услуги сиделки {serviceType} для себя или своих близких {location}!
        </p>
        <p className="article_text">
          Не упустите возможность предоставить своим близким комфортное
          и безопасное окружение с профессиональной помощью сиделки. Оформите
          заказ на нашем сайте и выберите удобное для вас время. Мы готовы
          обеспечить вашим близким качественный уход и внимание. Помощь доступна
          уже сегодня — выберите подходящего специалиста прямо сейчас!
        </p>
        <h3 className="subtitle">
          Сколько стоят услуги сиделки {location} {serviceType}?
        </h3>
        <p className="article_text">
          Стоимость услуг сиделки {location} {serviceType} начинается от{" "}
          {priceFrom} рублей. Цена зависит от продолжительности смены, необходимых
          дополнительных услуг и выбранного специалиста. Мы предлагаем разные
          пакеты услуг, которые можно подобрать в зависимости от ваших
          предпочтений и бюджета. Рекомендуем обращаться заранее, чтобы выбрать
          удобное время и подходящего специалиста!
        </p>
      </article>
    </section>
  );
};

export default SeoArticle;

import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Flex } from "antd";

const Header: React.FC = () => {
  const navigate = useNavigate();
  return (
    <header className="header">
      <img src="/logo.png" alt="logo" onClick={() => navigate("/")} className="header_logo"/>
      <Flex gap="small" wrap>
      <Button color="primary" variant="solid" onClick={() => navigate("/листинг/Для%20пожилого%20человека")}>
        Сиделки
      </Button>
      <Button color="primary" variant="solid" onClick={() => navigate("/профиль/")}>
        Личный кабинет
      </Button>
      </Flex>
    </header>
  );
};

export default Header;
